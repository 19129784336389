<template>
  <div class="exercisesDetail">
    <div class="box">
      <div class="subject">
        <div class="content" style="position:relative;">
          <div class="text">
            <div class="topic">
              <!--              {{exercises.questionName}}-->
              练习题题目
              <span class="top-span">题型：{{ exercises.questionTypeName }}</span>
              <span class="top-span">类型：{{ exercises.categoryName }}</span>
              <span class="top-span">难度：{{ exercises.levelName }}</span>
            </div>
            <div class="detail" style="padding: 0 25px;word-break: break-word" v-html="exercises.questionInformation" />
            <div v-if="exercises.questionTypeName==='简答题'||exercises.questionTypeName==='编程题'" class="btn"><el-button type="text" @click="downLoad">下载附件</el-button></div>
          </div>
          <!--上一题-->
          <div class="circle_bu" style="position: absolute;right: -30px;bottom: 30px;" @click="previous">
            <div :class="current===1?'previousTop':'previous'">
              上一题
            </div>
          </div>
        </div>
      </div>
      <div style="position:relative;">
        <!--下一题-->
        <div class="circle_bu" style="position: absolute;right: -30px;top: -40px" @click="next">
          <div :class="current===total?'nextBotton':'next'">
            下一题
          </div>
        </div>
        <!--提交-->
        <div class="circle_bu" style="position: absolute;right: -30px;top: 30px" @click="submitPre">
          <div class="right-submit">
            保存
          </div>
        </div>
        <div v-if="exercises.questionTypeName==='选择题'" class="studentAnswer">
          <div>
            <div class="title">解答 </div>
          </div>
          <div>
            <div class="input-c" style="padding: 0 25px"><el-select v-model="currentStuAnswer" placeholder="请选择答案" size="small">
              <el-option
                v-for="dict in answerList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select></div>
          </div>
        </div>
        <div v-else class="studentAnswer">
          <div>
            <div class="title">解答
              <div v-if="exercises.questionTypeName!=='填空题'" style="float: right;margin-right: 20px;">
                <FileUpload :key="exercises.id" :btn-value="'上传答案附件'" :value="exercises.urlModel" :limit="1" :type="{type:5}" @input="inputList" />
              </div>
            </div>
          </div>
          <div>
            <div><el-input
              v-model="currentStuAnswer"
              class="text"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 100}"
              :rows="2"
              :maxlength="500"
              :show-word-limit="true"
              placeholder="填空题答案请用英文逗号(',')分隔开"
            /></div>
          </div>
        </div>

        <div v-if="exercises.publishType!==0" class="answer">
          <div class="content">
            <div class="title">答案</div>
            <div class="text" style="padding: 0 25px" v-html="exercises.answerInformation" />
            <div class="btn"><el-button v-if="exercises.questionTypeName==='简答题'||exercises.questionTypeName==='编程题'" type="text" @click="downLoadAnswer">下载附件</el-button></div>
          </div>
        </div>
      </div>

      <div class="page">
        <!--        <div class="submit-c">-->
        <!--          <el-button style="margin-left: 200px;color: #FFFFFF"  type="primary" @click="submitPre"  size="medium"  round>保存</el-button>-->
        <!--        </div>-->
        <div class="btn" style="float: right">
          <!--          <el-button type="text" @click="previous">上一题</el-button>-->
          <span style="color: #39A5EF;padding-left: 19px">第{{ current }}题</span>
          <span style="padding-right: 19px">/共{{ total }}题</span>
          <!--          <el-button type="text" @click="next">下一题</el-button>-->
        </div>
      </div>
      <div />
    </div>
    <indexBottom />
  </div>
</template>

<script>
import FileUpload from '../../components/FileUpload'
import indexBottom from '../../components/indexBottom'
import { mapState } from 'vuex'
import { getExercisesDetail, getExercisesIdList, submitExervises } from '../../api/course'
export default {
  name: 'ExercisesDetail',
  components: { indexBottom, FileUpload },
  created() {
    this.currentPublishId = this.$route.query.id
    // 处理字典值
    this.dealWithArrToMap()
    this.getDetail()
    this.getExercisesIdMap()
  },
  computed: {
    ...mapState({
      answerList: state => state.dict.dictTree.work_answer['arr'],
      ea_question_type: state => state.dict.dictTree.ea_question_type['arr'],
      ea_question_category: state => state.dict.dictTree.ea_question_category['arr'],
      ea_question_difficulty: state => state.dict.dictTree.ea_question_difficulty['arr']
    })
  },
  data() {
    return {
      currentPublishId: '',
      current: 1,
      total: 1,
      select: '',
      currentStuAnswer: '',
      answerUrls: '',
      submitable: false,
      feedback: '',
      // 练习题详情
      exercises: {},
      questionTypeMap: {},
      questionCategoryMap: {},
      questionDifficultyMap: {},
      questionList: [{ questionName: '', answerInformation: '', questionInformation: '', publishType: 0 }],
      question: {
        title: '题目题目题目',
        content: '内容内容内容内容内容内容'
      },
      exercisesIdMap: []

    }
  },
  methods: {
    getDetail() {
      getExercisesDetail({ id: this.currentPublishId }).then(res => {
        this.exercises = res.data
        this.exercises.questionTypeName = this.questionTypeMap[this.exercises.questionType + '']
        this.exercises.categoryName = this.questionCategoryMap[this.exercises.type + '']
        this.exercises.levelName = this.questionDifficultyMap[this.exercises.level + '']
        this.currentStuAnswer = this.exercises.studentAnswer
        if (this.exercises.studentUrls !== null && this.exercises.studentUrls !== '') {
          this.exercises.urlModel = [{
            url: this.exercises.studentUrls,
            name: '附件'
          }]
        } else {
          this.exercises.urlModel = ''
        }
        console.log(this.exercises)
      })
    },
    inputList(data) {
      console.log(data)
      if (data.length > 0) {
        this.exercises.studentUrls = data.map(i => i.url).toString()
      } else {
        this.exercises.studentUrls = ''
      }
    },
    // 获取数组下标
    getExercisesIdMap() {
      // 获取总共多少练习题，即练习题列表
      getExercisesIdList().then(res => {
        console.log(res)
        this.total = res.total
        // 为防止出现重复数据，先清空
        this.exercisesIdMap = []
        res.rows.forEach(item => {
          this.exercisesIdMap.push(item.publishId)
        })
        this.current = this.exercisesIdMap.indexOf(this.currentPublishId) + 1
        console.log(this.exercisesIdMap.indexOf(this.currentPublishId))
        console.log(this.currentPublishId)
        console.log(this.exercisesIdMap)
      })
    },
    // 处理题型，难度，类型字典值为map方便使用
    dealWithArrToMap() {
      this.ea_question_type.forEach(item => {
        const key = item.value
        this.questionTypeMap[key] = item.label
      })
      this.ea_question_category.forEach(item => {
        const key = item.value
        this.questionCategoryMap[key] = item.label
      })
      this.ea_question_difficulty.forEach(item => {
        const key = item.value
        this.questionDifficultyMap[key] = item.label
      })
    },
    previous() {
      if (this.current === 1) {
        this.$message.warning('当前已是第一题')
      } else {
        console.log(this.currentStuAnswer)
        if ((this.currentStuAnswer !== '' && this.currentStuAnswer !== null) || (this.exercises.studentUrls !== '' && this.exercises.studentUrls !== null)) {
          const obj = {
            id: this.currentPublishId,
            answerInformation: this.currentStuAnswer,
            answerUrls: this.exercises.studentUrls
          }
          submitExervises(obj).then(res => {
            console.log(res)
            if (res.code === 200) {
              // 进行及时的回显
              if (this.exercises.studentUrls !== '') {
                this.exercises.urlMode = [{
                  url: this.exercises.studentUrls,
                  name: '附件'
                }].concat()
              } else {
                this.exercises.urlModel = ''.concat()
              }
              this.current--
              console.log('上一题')
              this.currentPublishId = this.exercisesIdMap[this.current - 1]
              this.getDetail()
            }
          })
        } else {
          this.current--
          console.log('上一题')
          this.currentPublishId = this.exercisesIdMap[this.current - 1]
          this.getDetail()
        }
      }
    },
    next() {
      if (this.current === this.total) {
        this.$message.warning('当前已是最后一题')
      } else {
        console.log(this.currentStuAnswer)
        if ((this.currentStuAnswer !== '' && this.currentStuAnswer !== null) || (this.exercises.studentUrls !== '' && this.exercises.studentUrls !== null)) {
          const obj = {
            id: this.currentPublishId,
            answerInformation: this.currentStuAnswer,
            answerUrls: this.exercises.studentUrls
          }
          submitExervises(obj).then(res => {
            console.log(res)
            if (res.code === 200) {
              // 进行及时的回显
              if (this.exercises.studentUrls !== '') {
                this.exercises.urlMode = [{
                  url: this.exercises.studentUrls,
                  name: '附件'
                }].concat()
              } else {
                this.exercises.urlModel = ''.concat()
              }

              this.current++
              console.log('下一题')
              this.currentPublishId = this.exercisesIdMap[this.current - 1]
              this.getDetail()
            }
          })
        } else {
          this.current++
          console.log('下一题')
          this.currentPublishId = this.exercisesIdMap[this.current - 1]
          this.getDetail()
        }
      }
    },
    // 保存
    saveExercises(data) {
      // data是否提示，1提示，0不提示
      const obj = {
        id: this.currentPublishId,
        answerInformation: this.currentStuAnswer,
        answerUrls: this.exercises.studentUrls
      }
      submitExervises(obj).then(res => {
        console.log(res)
        if (res.code === 200 && data === 1) {
          this.$message.success('保存成功')
          // 进行及时的回显
          if (this.exercises.studentUrls !== '') {
            this.exercises.urlMode = [{
              url: this.exercises.studentUrls,
              name: '附件'
            }].concat()
          } else {
            this.exercises.urlModel = ''.concat()
          }
        }
      })
    },
    // 提交前的弹框
    submitPre() {
      this.saveExercises(1)
    },
    workSubmit() {

    },
    // 上传
    upload() {

    },
    downLoad() {
      console.log(this.current)
      console.log(this.questionList[this.current - 1])
      if (this.exercises.questionUrls == '' || this.exercises.questionUrls == null || this.exercises.questionUrls == undefined) {
        this.$message.warning('该试题没有附件')
      } else {
        this.$download.name(this.exercises.questionUrls)
      }
    },
    downLoadAnswer() {
      if (this.exercises.answerUrls == '' || this.exercises.answerUrls == null || this.exercises.answerUrls == undefined) {
        this.$message.warning('该试题答案没有附件')
      } else {
        this.$download.name(this.exercises.answerUrls)
      }
    }
  }
}
</script>

<style scoped lang="less">
/*/deep/.el-dialog__header .el-dialog__title{*/
/*    color: #66b1ff !important;*/
/*}*/
/*/deep/.el-dialog{*/
/*    min-height: 380px;*/
/*}*/

.exercisesDetail {
  /deep/.el-dialog {
    min-height: 200px;
  }
}

/deep/.mydialog{
  color: #66b1ff
}
.exercisesDetail{
  margin: 0 auto 17px;
  .box{
    margin: 0 auto;
    width: 1200px;
    box-sizing: border-box;
    /*background: #FFFFFF;*/
    >.subject{
      //padding-top: 19px;
    }
    .title{
      text-align: left;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: 20px;
    }
    .content{
      padding: 20px;
      margin: 0 auto;
      /*width: 1160px;*/
      min-height: 270px;
      /*background: #EEF5FB;*/
      background: white;
      /*overflow: auto;*/
      border-radius: 10px;
      .text{
        min-height: 200px;
        text-align: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        .topic{
          font-size: 18px;
          .top-span{
            font-size: 14px;
            display: inline-block;
            padding: 0 20px;
          }
        }
        .detail{
          min-height: 200px;
          margin-top: 20px;
        }
      }
      .btn{
        margin-top: 10px;
        text-align: left;
        /*margin-right: 20px;*/
      }

    }
    >.page{
      height: 20px;
      text-align: center;
      margin: 20px 24px 20px 0;
      .el-button{
        color: #999999;
      }
      span{
        font-size: 14px;
        color: #999999;
      }
    }
  }
}
.studentAnswer{
  margin: 20px auto;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  min-height: 270px;
  .text{
    padding: 20px;
    text-align: left;
    min-height: 200px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    .topic{
      font-size: 18px;
    }
  }
  .title{
    margin-bottom: 20px;
  }

}

.input-c{
  text-align: left;
}
.submit-c{
  text-align: center;
  padding: 10px 0;
}
.input-textarea{

}

.text-center{
  text-align: center;
}
.circle_bu{
  cursor: pointer;
  .previous{
    width: 60px;
    height:60px;
    color: #4d97fe;
    font-weight: 600 ;
    font-size:14px;
    line-height: 60px;
    box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
    border-radius: 50px;
    background-color: #FFFFFF
  }
  .previousTop{
    width: 60px;
    height:60px;
    color: #FFFFFF;
    font-weight: 600 ;
    font-size:14px;
    line-height: 60px;
    box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
    border-radius: 50px;
    background-color: #c5c8cb
  }
  .next{
    width: 60px;
    height:60px;
    color: #4d97fe;
    font-weight: 600 ;
    font-size:14px;
    line-height: 60px;
    box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
    border-radius: 50px;
    background-color: #FFFFFF
  }
  .nextBotton{
    width: 60px;
    height:60px;
    color: #FFFFFF;
    font-weight: 600 ;
    font-size:14px;
    line-height: 60px;
    box-shadow: 0px 0px 10px  rgba(0,41,127,0.2);
    border-radius: 50px;
    background-color: #c5c8cb
  }
  .right-submit{
    padding: 10px;
    width: 60px;
    height:60px;
    color: #FFFFFF;
    font-size:14px;
    line-height: 40px;
    box-shadow: 0px 0px 10px rgba(0,41,127,0.2);
    border-radius: 50px;
    background-color: #4d97fe
  }
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
</style>
